<template>
  <div class="fundo">
    <div class="p-2 text-center d-flex flex-column text-light">
      <div>
        <img class="logotipo" src="@/assets/images/polgo-logotipo.svg" alt="Polgo logo" />
      </div>
      Não foi possível encontrar a campanha solicitada, <br />
      tente novamente mais tarde.
      <div>{{ msgErro }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'erro-configuracao',
  props: ['msgErro'],
};
</script>
<style lang="scss" scoped>
.fundo {
  background-color: blueviolet;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logotipo {
  // max-height: 100%;
  width: 500px;
  max-width: 100%;
}
// }
</style>

<template>
  <form id="form_cadastro" @submit.prevent="checkForm">
    <div class="w-100 d-flex flex-column align-items-center container-cadastro">
      <!-- Título -->
      <div class="w-80 d-flex flex-column align-items-center mt-4 mb-4">
        <p class="f-8 text-center" style="color: var(--text-color-highlight)">
          <strong>Parece que você ainda não tem cadastro.</strong>
          <br />
          Para que possamos fornecer a melhor experiência possível, precisamos de algumas informações
        </p>
      </div>
      <!-- Telefone/CPF|CNPJ -->
      <div
        class="w-75 d-flex align-items-center justify-content-center"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
      >
        <!-- Telefone -->
        <base-pill-input
          v-if="!config.LOGIN_CPF"
          id="telefone-usuario"
          type="tel"
          :autocomplete="false"
          placeholder="Telefone"
          :inputClasses="' destacar-placeholder'"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-phone']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['(##)#####-####']"
          :masked="false"
          v-model="$v.phone.$model"
          :valid="!$v.phone.$invalid"
          :error="$v.phone.$error"
        />

        <!-- CPF|CNPJ -->
        <base-pill-input
          v-else
          id="cpf-cnpj-usuario"
          type="tel"
          :autocomplete="false"
          :inputClasses="' destacar-placeholder'"
          :placeholder="
            this.config.TIPO_USUARIO == 'cpfCnpj' ? 'CPF/CNPJ' : this.config.TIPO_USUARIO == 'cnpj' ? 'CNPJ' : 'CPF'
          "
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-id-card']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="
            this.config.TIPO_USUARIO == 'cpfCnpj'
              ? ['###.###.###-##', '##.###.###/####-##']
              : this.config.TIPO_USUARIO == 'cnpj'
              ? ['##.###.###/####-##']
              : ['###.###.###-##']
          "
          :masked="false"
          v-model="$v.phone.$model"
          :defaultValue="$v.phone.$model"
          :valid="!$v.phone.$invalid"
          :error="$v.phone.$error"
        />
      </div>
      <!-- Nome -->
      <div
        class="w-75 d-flex align-items-center justify-content-center"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
      >
        <!-- Nome -->
        <!-- <base-input
              autocomplete="off"
              addonLeftIcon="fas fa-user"
              placeholder="Nome"
              type="text"
              v-model="$v.name.$model"
              :valid="!$v.name.$invalid"
              :error="$v.name.$error"
            >
              <template slot="errorBlock">
                <span v-if="!$v.name.minLength">
                  Nome deve conter no mínimo 3 caracters e 1 espaço entre eles
                </span>
                <span v-else-if="!$v.name.temEspacoEmBranco">
                  Nome não deve conter espaços em branco no inicio e no final
                </span>
                <span v-else-if="!$v.name.isName">
                  Nome deve ser válido contendo Nome e Sobrenome. Ex: "João
                  Carmino"
                </span>
              </template>
</base-input> -->
        <base-pill-input
          id="nome-usuario"
          type="text"
          :autocomplete="false"
          :placeholder="
            this.config.TIPO_USUARIO == 'cpfCnpj'
              ? 'Nome Completo / Razão Social'
              : this.config.TIPO_USUARIO == 'cnpj'
              ? 'Razão Social'
              : 'Nome Completo'
          "
          :left-middle-pill="true"
          :inputClasses="' destacar-placeholder'"
          class="w-100 mb-3"
          :icons="['fas fa-user']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.name.$model"
          :defaultValue="$v.name.$model"
          :valid="!$v.name.$invalid"
          :error="$v.name.$error"
        />
      </div>
      <!-- E-mail -->
      <div
        class="w-75 d-flex align-items-center justify-content-center"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
      >
        <!-- E-mail -->
        <base-pill-input
          id="email-usuario"
          type="email"
          :autocomplete="false"
          :inputClasses="' destacar-placeholder'"
          placeholder="E-mail"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-envelope']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.email.$model"
          v-if="
            !this.config.MODIFICAR_CAMPOS_PERFIL ||
            (this.config.MODIFICAR_CAMPOS_PERFIL && this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email'))
          "
          :defaultValue="$v.email.$model"
          :valid="!$v.email.$invalid"
          :error="$v.email.$error"
        />
      </div>
      <!-- Data nsc. e Gênero -->
      <div
        class="d-flex flex-row justify-content-between w-75 mb-3"
        v-if="
          config.CAMPOS_OBRIGATORIOS_PERFIL &&
          (config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') ||
            config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero'))
        "
      >
        <!-- Data nasc. -->
        <base-pill-input
          id="data-nascimento-usuario"
          type="tel"
          placeholder="Data Nascimento"
          :inputClasses="' destacar-placeholder'"
          :left-middle-pill="true"
          :icons="['fas fa-birthday-cake']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['##/##/####']"
          :masked="true"
          v-model="$v.birth.$model"
          :defaultValue="$v.birth.$model"
          :valid="!$v.birth.$invalid"
          :error="$v.birth.$error"
          :class="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero') ? 'w-45' : 'w-100'"
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento')"
        />
        <!-- Gênero -->
        <base-pill-select
          id="genero-usuario"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light destacar"
          selectSize="average"
          :optionClass="'destacar'"
          :options="genderOptions"
          v-model="$v.gender.$model"
          :defaultOption="$v.gender.$model"
          :error="$v.gender.$error"
          :class="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') ? 'w-50' : 'w-100'"
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero')"
        />
      </div>

      <!-- Telefone contato -->
      <div
        class="w-75 d-flex align-items-center justify-content-center"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
        v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('telefoneContato')"
      >
        <!-- Telefone -->
        <base-pill-input
          id="telefone-contato"
          type="tel"
          :inputClasses="' destacar-placeholder'"
          :autocomplete="false"
          :placeholder="config.VERIFICAR_CELULAR_CADASTRO ? 'Número de celular' : 'Telefone contato'"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-phone']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['(##)#####-####']"
          :masked="false"
          v-model="$v.telefoneContato.$model"
          :valid="config.VERIFICAR_CELULAR_CADASTRO ? verificadorTelefoneContatoValido : !$v.telefoneContato.$invalid"
          :error="config.VERIFICAR_CELULAR_CADASTRO ? verificadorTelefoneContatoValido : $v.telefoneContato.$error"
          @input="verificaNumeroTelefoneContato"
        />
      </div>
      <!-- Pergunta e opções -->
      <base-pill-select
        v-if="config.MODULO_PERGUNTA_E_RESPOSTA"
        id="pergunta-opcoes-usuario"
        class="w-75 mb-3"
        selectPillClass="select-pill-primary"
        selectBodyClass="select-body-light destacar"
        selectSize="average"
        :options="opcoesResposta"
        :defaultOption="resposta || respostaAnterior"
        v-model="resposta"
      />
      <!-- Extra -->
      <base-pill-input
        v-if="config.CONSUMIDOR_CAMPO_EXTRA_ATIVO"
        :autocomplete="false"
        id="extra"
        :inputClasses="' destacar-placeholder'"
        type="text"
        :placeholder="config.CONSUMIDOR_CAMPO_EXTRA_NOME || 'Extra'"
        :left-middle-pill="true"
        class="w-75 mb-3"
        :icons="['fas fa-user-tie']"
        :pillColors="['pill-dark']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        iconValidation="fas fa-info-circle"
        :valid="true"
        v-model="extra"
        :errorMessageValidation="config.CONSUMIDOR_CAMPO_EXTRA_NOME"
      />
      <!-- Senha -->
      <div
        class="w-75 d-flex flex-column validacaoGrupo"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
      >
        <base-pill-input
          id="senha-usuario"
          :inputClasses="' destacar-placeholder'"
          type="password"
          :autocomplete="false"
          placeholder="Senha"
          :left-middle-pill="true"
          class="w-100 mb-2"
          :icons="['fas fa-lock']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.password.$model"
          :valid="!$v.password.$invalid"
          :error="$v.password.$error"
        />

        <div class="flex-column d-flex mb-3 validacaoErro">
          <!-- <span class="aviso-senha aviso-senha-bg" style="color: blue; font-size: 12px">
          <i class="fas fa-info-circle"></i> Escolha uma nova senha para acessar o aplicativo, com no mínimo 8
          caracteres.
        </span> -->
          <!-- <span v-if="!$v.password.minLength" class="aviso-senha aviso-senha-bg">
        <i class="fas fa-exclamation-circle"></i> Senha deve conter no mínimo 6 dígitos
      </span> -->

          <div class="aviso-senha d-flex aviso-senha-bg" :style="{ color: $v.password.minLength ? 'green' : 'red' }">
            <div class="d-flex align-items-center justify-content-center">
              <i v-if="$v.password.minLength" class="fa fa-check"></i>
              <span v-if="!$v.password.minLength" style="font-weight: bold">X</span>
            </div>
            Senha deve conter no mínimo 8 caracteres
          </div>
          <div
            class="aviso-senha d-flex aviso-senha-bg mb-1 mt-1"
            :style="{ color: $v.password.containsUpperAndLower ? 'green' : 'red' }"
          >
            <div class="d-flex align-items-center">
              <i v-if="$v.password.containsUpperAndLower" class="fa fa-check"></i
              ><span v-if="!$v.password.containsUpperAndLower" style="font-weight: bold">X</span>
            </div>
            Senha deve conter letras maiúsculas e minúsculas
          </div>
          <div
            class="aviso-senha d-flex aviso-senha-bg"
            :style="{ color: $v.password.containsNumbers ? 'green' : 'red' }"
          >
            <div class="d-flex align-items-center">
              <i v-if="$v.password.containsNumbers" class="fa fa-check"></i
              ><span v-if="!$v.password.containsNumbers" style="font-weight: bold">X</span>
            </div>
            Senha deve conter números
          </div>
        </div>
      </div>
      <!-- Mensagem informativa sobre a senha -->

      <!-- Confirmar senha -->
      <div
        class="w-75 d-flex align-items-center justify-content-center"
        :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
      >
        <base-pill-input
          id="confirmar-senha-usuario"
          :inputClasses="' destacar-placeholder'"
          type="password"
          :autocomplete="false"
          placeholder="Confirmar senha"
          :left-middle-pill="true"
          class="w-100 mb-2"
          :icons="['fas fa-lock']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.confirmPassword.$model"
          :valid="!$v.confirmPassword.$invalid"
          :error="$v.confirmPassword.$error"
        />
      </div>
      <!-- Mensagem informativa sobre a confirmação da senha -->
      <div class="row d-flex w-70">
        <span v-if="password === confirmPassword"></span>

        <span
          v-else-if="$v.confirmPassword.$invalid && confirmPassword.length >= 1"
          class="aviso-senha aviso-senha-bg mb-3 d-flex align-items-center"
        >
          <span class="iconConfimacaoSenha" style="font-weight: bold">X</span> A confirmação de senha deve ser igual
        </span>
      </div>

      <!-- Endereço -->
      <div
        class="d-flex flex-column justify-content-center w-75"
        v-if="config.CAMPOS_OBRIGATORIOS_PERFIL && config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco')"
      >
        <!-- CEP e UF -->
        <div class="d-flex flex-row justify-content-center w-100 mt-2">
          <p style="color: var(--text-color-highlight)">
            <strong>Endereço</strong>
          </p>
        </div>
        <!-- CEP -->
        <base-pill-input
          id="cep-usuario"
          :inputClasses="' destacar-placeholder'"
          type="tel"
          placeholder="CEP"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-map-marker-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['#####-###']"
          :masked="true"
          v-model="$v.cep.$model"
          :defaultValue="$v.cep.$model"
          :valid="!$v.cep.$invalid"
          :error="$v.cep.$error"
        />
        <!-- UF -->
        <base-pill-select
          id="uf-usuario"
          class="w-100 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light destacar"
          selectSize="average"
          :options="ufs"
          v-model="$v.state.$model"
          :defaultValue="$v.state.$model"
          :defaultOption="$v.state.$model"
          :error="$v.state.$error"
          @change="buscaCidades(ufs.filter((e) => $v.state.$model == e.value)[0].idIBGE)"
        />
        <!-- Rua -->
        <base-pill-input
          id="rua-usuario"
          :inputClasses="' destacar-placeholder'"
          type="text"
          placeholder="Rua"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.street.$model"
          :defaultValue="$v.street.$model"
          :valid="!$v.street.$invalid"
          :error="$v.street.$error"
        />
        <!-- Número e Bairro -->
        <div class="d-flex flex-row justify-content-between w-100">
          <base-pill-input
            id="numero-endereco-usuario"
            :inputClasses="' destacar-placeholder'"
            type="tel"
            placeholder="N°"
            :left-middle-pill="true"
            class="w-40 mb-3"
            :icons="['fas fa-home']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :mask="['######']"
            :masked="false"
            v-model="$v.number.$model"
            :defaultValue="$v.number.$model"
            :valid="!$v.number.$invalid"
            :error="$v.number.$error"
          />

          <base-pill-input
            id="bairro-usuario"
            :inputClasses="' destacar-placeholder'"
            type="text"
            placeholder="Bairro"
            :left-middle-pill="true"
            class="w-55 mb-3"
            :icons="['fas fa-map-marked-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.neighborhood.$model"
            :defaultValue="$v.neighborhood.$model"
            :valid="!$v.neighborhood.$invalid"
            :error="$v.neighborhood.$error"
          />
        </div>
        <!-- Cidade e complemento -->
        <base-pill-input
          id="cidade-usuario"
          :inputClasses="' destacar-placeholder'"
          type="text"
          placeholder="Cidade"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.city.$model"
          :defaultValue="$v.city.$model"
          :valid="!$v.city.$invalid"
          :error="$v.city.$error"
          listId="nomeCidade"
          :dataList="true"
          :listOptions="listaCidades"
        />

        <base-pill-input
          id="complemento-endereco-usuario"
          :inputClasses="' destacar-placeholder'"
          type="text"
          placeholder="Complemento"
          :left-middle-pill="true"
          class="w-100 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="complement"
          :defaultValue="complement"
          :valid="true"
        />

      </div>

      <!-- Mensagem informativa sobre a confirmação da senha -->
      <div class="row d-flex w-70">
        <span v-if="password === confirmPassword"></span>

        <!-- <span v-else-if="$v.confirmPassword.$invalid && confirmPassword.length >= 1" class="aviso-senha aviso-senha-bg">
          <i class="fas fa-exclamation-circle"></i> A confirmação de senha deve ser igual
        </span> -->
      </div>

      <!-- Loja mais próxima -->
      <!-- <div class="d-flex flex-row justify-content-center w-100 mt-2">
        <p style="color: var(--text-color-highlight)">
          <strong>Você ja é cliente nosso?</strong>
        </p>
      </div> -->

      <div class="row d-flex w-75">
        <base-pill-select
          id="jaECliente"
          class="w-100 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light"
          selectSize="average"
          :options="[
            {
              value: null,
              desc: 'Você já é cliente '+config.NOME_EMPRESA+'?',
            },
            {
              value: true,
              desc: 'SIM',
            },
            {
              value: false,
              desc: 'NÃO',
            },
          ]"
          :defaultOption="null"
          v-model="jaECliente"
          v-if="config.CADASTRO_LOJAMAISPROXIMA"
        />

        <base-pill-select
          id="lojaMaisProxima"
          class="w-100 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light"
          selectSize="average"
          :options="lojasMaisProximasOptions"
          :defaultOption="lojasMaisProximasOptions[0].value"
          v-model="lojaMaisProxima"
          v-if="config.CADASTRO_LOJAMAISPROXIMA && jaECliente === 'false' && lojasMaisProximas.length"
        />
      </div>
      <!-- Termos de uso -->
      <div class="w-75 pt-3 pb-3 d-flex align-items-center justify-content-center" v-if="config.CONCORDAR_TERMOS">
        <base-pill-check-box
          id="termos"
          name="termos"
          class="w-100"
          :pillSize="'pill-average'"
          v-model="acceptTerms"
          :selectedValue="acceptTerms"
          :campoSwitch="true"
          @click="alternarTermosAceitos()"
        >
          <template v-slot:pill-check-box-title>
            <label class="pl-5 pr-3 mb-0 f-065 light-gray" for="terms">
              Eu li e concordocom os termos de uso:
              <strong>Política de privacidade</strong>
            </label>
          </template>
        </base-pill-check-box>
      </div>
      <!-- Regulamento -->
      <div class="w-75 pt-3 pb-3 d-flex align-items-center justify-content-center" v-if="config.CONCORDAR_REGULAMENTO">
        <base-pill-check-box
          id="regulamento"
          name="regulamento"
          class="w-100"
          :pillSize="'pill-average'"
          :selectedValue="aceitarRegulamento"
          :campoSwitch="true"
          @click="alternarRegulamentoAceito()"
        >
          <template v-slot:pill-check-box-title>
            <label class="pl-5 pr-3 mb-0 f-065 light-gray" for="regulamento">
              Li e aceito o
              <a href="regulamento">regulamento</a>
              da promoção.
            </label>
          </template>
        </base-pill-check-box>
      </div>
      <!-- NO FEATURE POINT -->
      <div class="w-75 mt-2 d-flex align-items-center justify-content-center" v-if="config.LOGIN_ASSOCIADO">
        <span class="sign-up-as text-outside text-uppercase">
          <strong>Me cadastrar como empresa</strong>
        </span>
      </div>
      <!-- Aceite de notificações -->
      <div
        class="w-75 pt-3 pb-3 d-flex align-items-center justify-content-end"
        v-if="config.CONCORDAR_RECEBER_MENSAGENS"
      >
        <small class="d-flex align-items-center" style="font-size: 0.7rem; padding-right: 10px">{{
          config.TEXTO_RECEBER_MENSAGENS || 'Aceito receber informações e comunicações'
        }}</small>
        <base-pill-toggle-button
          toggleSize="toggle-button-average"
          :checked="aceitaNotificacao"
          @click="alternarCampoNotificacao"
        />
      </div>
      <!-- Termos obrigatórios -->
      <div
        v-if="config.TEXTO_ACEITE"
        class="w-75 pt-3 pb-3 d-flex align-items-center justify-content-center"
        style="font-size: 0.8rem"
      >
        <span>
          * Ao clicar em cadastrar, aceito o
          <u @click="navigateTo('regulamento')" style="cursor: pointer">regulamento da {{ config.NOME_PROMOCAO }}</u
          >,
          <u
            @click="
              config.POLITICA_PRIVACIDADE_PERSONALIZADA
                ? navigateTo('politicaPrivacidadePersonalizada')
                : navigateTo('politicaPrivacidade')
            "
            style="cursor: pointer"
          >
            política de privacidade</u
          >
          <span v-if="config.HABILITAR_TERMOS_ACEITE_LGPDS">
            e
            <u @click="navigateTo('termos')" style="cursor: pointer"> termos de aceite </u>
          </span>
        </span>
      </div>
      <!-- Navegar -->
      <div class="w-75 d-flex align-items-center justify-content-between pb-5 pt-4">
        <!-- @click="navigateBack" -->
        <base-pill-button
          :type="'light'"
          :left-middle-pill="'fas fa-chevron-circle-left'"
          :pillColors="['pill-dark']"
          :pillIconColors="['icon-light']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
          :pillSize="['pill-average']"
          :disabled="loading"
          @click="voltarTela()"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 pl-2 pr-2 text-center f-07 text-black">Voltar</span>
          </template>
        </base-pill-button>

        <base-pill-button
          id="form_cadastro_disparo"
          :type="'light'"
          :right-middle-pill="'fas fa-user-edit'"
          :pillColors="['pill-light']"
          :pillIconColors="['icon-dark']"
          :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
          :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
          :pillSize="['pill-average']"
          :disabled="loading"
          :res="config.TELA_REDIRECIONAR"
          @click="checkForm"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 pl-3 pr-2 text-center f-07 text-black">Cadastrar</span>
          </template>
        </base-pill-button>
      </div>

      <!-- Modal de confirmar número de celular -->
      <base-modal
        :show.sync="exibirModalAutenticacaoTemporaria"
        modal-classes="modal-dialog-top qr-modal"
        body-classes="p-0"
      >
        <template>
          <b-container>
            <div class="row d-flex justify-content-center m-4">
              <div class="w-75 d-flex flex-column align-items-center justify-content-center mb-3">
                <div class="row mr-2 ml-2 mb-3">
                  <span class="f-11 text-center diminuir-texto"
                    >Para se cadastrar, precisamos que confirme o número de seu celular, para isso, insira o código que
                    foi enviado para o número
                    <span class="font-weight-bold"> {{ formataTelefone(telefoneContato) }}</span> via
                    {{ textoExibicaoModalConfirmarCelular }}:</span
                  >
                </div>

                <base-pill-temporary-code
                  class="w-100"
                  v-model="codigoAutenticacaoTemporaria"
                  :key="contadorCodigoAutenticacaoTemporaria"
                />

                <span class="w-100 ml-2 mr-2 text-center text-black f-09 mt-3"
                  >Não recebeu o código? Clique no botão abaixo para reenviar.</span
                >
              </div>
              <!-- Reenviar -->
              <base-pill-button
                :type="'light'"
                :right-middle-pill="'fas fa-chevron-circle-right'"
                :pillColors="['pill-light']"
                :pillIconColors="['icon-dark']"
                :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
                :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
                :pillSize="['pill-average']"
                :disabled="enviandoRequisicaoConfirmarCelular || tempoInicial > 0"
                @click="reenviarRequisicaoConfirmarCelular"
              >
                <template v-slot:pill-button-title>
                  <span class="w-90 pl-3 pr-2 text-center text-black f-07"
                    >Reenviar {{ tempoInicial > 0 ? `(${tempoInicial})` : '' }}</span
                  >
                </template>
              </base-pill-button>
            </div>
          </b-container>
        </template>
      </base-modal>
    </div>
  </form>
</template>

<script>
import { authentication } from '@/lib/Authentication';
import { BRASIL_STATES, Data, Validation } from '@/lib/Helper';
import { campaign } from '@/lib/Storage';
import { helpers, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';

const { regex } = helpers;

export default {
  name: 'TheCadastro',
  destroyed() {
    this.setLoader(false);
  },
  created() {
    // Escondendo a logo
    if (this.config.TELA_CADASTRO_NOVO) {
      let logoQR, logoCampanha, menuInferior;
      let intervalo = setInterval(() => {
        logoQR = document.querySelector('.qr-logo');
        logoCampanha = document.querySelector('.campaing-logo');
        if (logoQR && logoCampanha) {
          logoCampanha.style.display = 'none';
          logoQR.style.display = 'none';
        }
        if (logoQR && logoCampanha) {
          clearInterval(intervalo);
        }
      }, 100);
    } else {
      // Reaparecendo a logo da campanha
      let logoCampanha = document.querySelector('.campaing-logo');
      let logoQR = document.querySelector('.qr-logo');
      if (logoCampanha) {
        logoCampanha.style.display = 'block';
      }
      if (logoQR) {
        logoQR.style.display = 'block';
      }
    }
  },

  async mounted() {
    setTimeout(this.inputFocus, 1000);

    if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') && this.config.CADASTRO_LOJAMAISPROXIMA) {
      var script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCX4JwhpiAx1NsLV74XVVd3yFrWR5cc-aA&loading=async&callback=initMap'
      );
      document.head.appendChild(script);

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: new google.maps.LatLng(this.latitude, this.longitude) }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                results[0].address_components.forEach((componente) => {
                  if (componente.types.includes('postal_code')) {
                    this.cep = componente.long_name;
                  }
                });
              }
            }
          });
        });
      }

      // const loader = new Loader('AIzaSyB8DtQoA3fVj2sTY1LIvbJhAwGUaajXi5A');
      // const google = await loader.load();
      // this.geocoder = new google.maps.Geocoder();
    }
    if (this.cpfPreRegistrado) {
      this.$v.phone.$model = this.cpfPreRegistrado;
    }
    if (this.primeiroAcessoDados) {
      this.$v.name.$model = this.primeiroAcessoDados.nome;
      this.$v.phone.$model = this.primeiroAcessoDados.cpf;
      if (!this.primeiroAcessoDados.email.endsWith('@null.com')) {
        this.$v.email.$model = this.primeiroAcessoDados.email;
      }
    }
    if (localStorage.getItem('dadosFormCadastro')) {
      const dadosRecuperados = JSON.parse(localStorage.getItem('dadosFormCadastro'));
      this.phone = dadosRecuperados.phone;
      this.email = dadosRecuperados.email;
      this.name = dadosRecuperados.name;
      this.telefoneContato = dadosRecuperados.telefoneContato;
      this.birth = dadosRecuperados.birth;
      this.gender = dadosRecuperados.gender;
      this.street = dadosRecuperados.street;
      this.number = dadosRecuperados.number;
      this.state = dadosRecuperados.state;
      this.neighborhood = dadosRecuperados.neighborhood;
      this.city = dadosRecuperados.city;
      this.cep = dadosRecuperados.cep;
      this.aceitaNotificacao = dadosRecuperados.aceitaNotificacao;
      this.extra = dadosRecuperados.extra;
      this.resposta = dadosRecuperados.resposta;
    }
  },

  beforeMount() {
    this.httpClient = new this.$httpClient();
    const { indicacao } = this.$route.query;
    this.indicacao = indicacao ? indicacao.split(';')[0] : null;

    const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
    const currentDate = Data.dataHoraAtual();
    this.loadCampaign({ currentDate, wsUrl });

    if (localStorage.getItem('login')) {
      const tela = this.telasConfiguraveis[0].destino || 'contaCorrente';
      if (this.$route.name != tela) this.$router.push({ name: tela });
    }
    // Vendo se login facebook está ativo e se tem dados ja obtidos anteriormente
    if (this.config.LOGIN_FACEBOOK && localStorage.getItem('facebookInfo')) {
      const dadosLoginFacebook = JSON.parse(localStorage.getItem('facebookInfo'));
      this.name = dadosLoginFacebook.nome;
      this.email = dadosLoginFacebook.email;
      this.facebook.token = dadosLoginFacebook.token;
      this.facebook.email = dadosLoginFacebook.email;
      localStorage.removeItem('facebookInfo');
    }

    // Auto-preencher campos de cadastro...
    if (this.autocompletarCadastro) this.verificarDadosAutocompletados();
    this.opcoesResposta =
      this.config.MODULO_PERGUNTA_E_RESPOSTA &&
      this.config.LISTA_OPCOES_DE_RESPOSTAS &&
      this.config.LISTA_OPCOES_DE_RESPOSTAS.length
        ? [this.config.PERGUNTA_AO_CONSUMIDOR, ...this.config.LISTA_OPCOES_DE_RESPOSTAS].map((e, i) => {
            return {
              value: i == 0 ? '' : e,
              desc: e,
            };
          })
        : [];

    if (this.phoneUsuario) {
      this.$v.phone.$model = this.phoneUsuario;
    }
  },

  beforeDestroy() {
    this.limparIntervaloTempo();
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      loading: 'consumidor/getLoaderRegistro',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
      stateSignupURL: 'signupURL/getSignupData',
      cpfPreRegistrado: 'consumidor/getCPF',
      primeiroAcessoDados: 'consumidor/getPrimeiroAcesso',
      campanha: 'campanha/getCampaign',
      aceites: 'aceites/getAceites',
    }),

    associateMode() {
      return this.$route.meta.associate;
    },

    autoCompletarEmail() {
      return this.config.MODIFICAR_CAMPOS_PERFIL && !this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email');
    },

    isProd: function () {
      if (typeof BuildInfo != 'undefined' && BuildInfo.packageName) {
        return this.config.VUE_APP_PROD_PACK_NAME == BuildInfo.packageName;
      } else {
        return location.href.startsWith(this.config.VUE_APP_PROD_BASE_URL);
      }
    },

    YYYY_MM_DD() {
      return this.birth.split('/').reverse().join('-');
    },
    primeiraLoja() {
      let lojaEscolhida = this.lojasMaisProximas.filter((v) => {
        return v.cnpjCpf == this.lojaMaisProxima;
      });
      return lojaEscolhida.length ? { cnpjCpf: lojaEscolhida[0].cnpjCpf, nome: lojaEscolhida[0].nome } : null;
    },
  },

  data: () => ({
    email: '',
    aceitarRegulamento: false,
    name: '',
    phone: '',
    password: '',
    confirmPassword: '',
    telefoneContato: '',
    indicacao: null,
    acceptTerms: false,
    errors: [],
    msgSenha: false,
    facebook: {
      token: null,
      email: null,
    },

    ufs: [{ value: '', desc: 'Estado' }, ...BRASIL_STATES],
    genderOptions: [
      { value: '', desc: 'Gênero ' },
      { value: 'masculino', desc: 'Masculino' },
      { value: 'feminino', desc: 'Feminino' },
      { value: 'outro', desc: 'Outro' },
    ],
    opcoesResposta: [],
    resposta: '',
    respostaAnterior: '',
    extra: '',
    jaECliente: null,
    lojasMaisProximasDefault: { value: null, desc: 'Seleciona a loja mais proxima de você' },
    lojasMaisProximas: [],
    latitude: null,
    longitude: null,
    lojaMaisProxima: null,
    geocoder: null,
    httpClient: null,
    // form data
    name: '',
    cpf: '',
    birth: '',
    telefoneContato: '',
    gender: null,
    typeOfPerson: 'fisica',
    email: '',
    // form data address
    street: '',
    number: '',
    state: null,
    neighborhood: '',
    city: '',
    complement: '',
    cep: '',
    aceitaNotificacao: true,
    identificacaoDinamica: '@',
    clientePicPay: false,
    identificacaoDinamicaInstagram: '@',
    semInstagramAntes: false,
    documentoEstrangeiroAtivo: false,
    documentoNumero: '',
    documentoJaCadastrado: false,
    listaCidades: [],

    verificadorTelefoneContatoValido: false,

    textoExibicaoModalConfirmarCelular: null,

    codigoAutenticacaoTemporaria: '',
    contadorCodigoAutenticacaoTemporaria: 0,
    tempoInicial: 30,
    intervaloReenviarConfirmacaoCelular: null,

    exibirModalAutenticacaoTemporaria: false,
    codigoAutenticacaoConfirmado: false,

    enviandoRequisicaoConfirmarCelular: false,
    telefoneContatoJaUtilizado: false,

    address: null
  }),

  methods: {
    ...mapActions({
      criarConta: 'consumidor/criarConta',
      consultaCpfRestrito: 'campanha/consultaCpfRestrito',
      loadCampaign: 'campanha/fetchCampaign',
      criarContaFacebook: 'consumidor/criarContaFacebook',
      updateProfile: 'consumidor/updateProfile',
      signIn: 'autenticacao/signIn',
      loadProfile: 'consumidor/fetchProfile',
      setCampaign: 'campanha/setStorageCampaign',
      solicitarCodigoAutenticacaoTemporaria: 'autenticacao/solicitarCodigoAutenticacaoTemporaria',
      verificaCodigoAutenticacaoTemporaria: 'autenticacao/verificaCodigoAutenticacaoTemporaria',
      verificaTelefoneContato: 'autenticacao/verificaTelefoneContatoLogin',
      obterLojasMaisProximas: 'associado/obterLojasMaisProximas',
    }),
    ...mapMutations({
      setLoader: 'consumidor/setLoaderRegistro',
    }),

    navigateTo(name) {
      localStorage.setItem(
        'dadosFormCadastro',
        JSON.stringify({
          phone: this.phone,
          email: this.email,
          name: this.name,
          telefoneContato: this.telefoneContato,
          birth: this.birth,
          gender: this.gender,
          street: this.street,
          number: this.number,
          state: this.state,
          neighborhood: this.neighborhood,
          city: this.city,
          cep: this.cep,
          aceitaNotificacao: this.aceitaNotificacao,
          extra: this.extra,
          resposta: this.resposta,
        })
      );
      this.$router.push({ name });
    },

    navigateBack() {
      this.$router.go(-1);
    },

    alternarCampoNotificacao() {
      this.aceitaNotificacao = !this.aceitaNotificacao;
      return this.aceitaNotificacao;
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-cadastro',
      });

      switch (this.telaPreCadastro) {
        case 'lgpd':
          this.$emit('alternar-exibicao', {
            event: 'alternar-tela-lgpd',
          });
          break;
        case 'lgpd-detalhada':
          this.$emit('alternar-exibicao', {
            event: 'alternar-tela-lgpd-detalhada',
          });
          break;
        case 'primeiro-acesso':
          this.$emit('alternar-exibicao', {
            event: 'alternar-tela-primeiro-acesso',
          });
          break;
        default:
          break;
      }
    },

    inputFocus() {
      const inputNomeUsuario = document.querySelector('#nome-usuario');
      inputNomeUsuario.focus();
    },

    alternarTermosAceitos() {
      this.acceptTerms = !this.acceptTerms;
      if (this.acceptTerms) {
        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'aceitePoliticaPrivacidade',
            category: 'CadastroUsuario',
            action: 'click',
            label: 'Clicou em aceitar política de privacidade',
          },
        });
      }
    },

    alternarRegulamentoAceito() {
      this.aceitarRegulamento = !this.aceitarRegulamento;
    },

    verificarDadosAutocompletados() {
      this.$v.name.$model = this.nomeUsuario;
      this.$v.email.$model = this.emailUsuario;
      this.$v.phone.$model = this.phoneUsuario;
    },

    preencherEmailUsuario: function () {
      if (this.autoCompletarEmail) {
        this.$v.email.$model = `${this.phone}@qrsorteios.com.br`;
      }
    },

    async checkForm(e) {
      if (localStorage.getItem('dadosFormCadastro')) {
        localStorage.removeItem('dadosFormCadastro');
      }
      let menorIdade = false;
      this.preencherEmailUsuario();
      this.errors = [];
      const invalidFields = [];
      let invalidFieldsString = null;

      if (this.config.VERIFICAR_CELULAR_CADASTRO) {
        if (!this.verificadorTelefoneContatoValido || !this.telefoneContato) {
          invalidFields.push('Número de celular');

          if (!this.$v.$invalid) {
            invalidFieldsString = invalidFields.join(', ');

            this.informarErrosAutenticacao('Ops...', `Campos inválidos: ${invalidFieldsString}`);
            return;
          }
        }
      }

      if (this.$v.$invalid) {
        if (this.$v.email.$invalid) {
          invalidFields.push('E-mail');
        }

        if (this.$v.name.$invalid) {
          invalidFields.push('Nome');
        }

        if (this.$v.phone.$invalid) {
          invalidFields.push(
            this.config.TIPO_USUARIO === 'cpfCnpj' ? 'CPF/CNPJ' : this.config.TIPO_USUARIO === 'cnpj' ? 'CNPJ' : 'CPF'
          );
        }

        if (this.$v.password.$invalid) {
          invalidFields.push(
            this.config.MENSAGEM_ERRO_VALIDACAO_SENHA ? this.config.MENSAGEM_ERRO_VALIDACAO_SENHA : 'Senha'
          );
        }

        if (this.$v.confirmPassword.$invalid) {
          invalidFields.push('Confirmação da senha');
        }

        if (!this.config.VERIFICAR_CELULAR_CADASTRO && this.$v.telefoneContato.$invalid) {
          invalidFields.push('Telefone');
        }

        if (this.config.VERIFICAR_IDADE_USUARIO && !this.verificaIdade()) {
          menorIdade = true;
        }

        if (this.$v.gender.$invalid) {
          invalidFields.push('Gênero');
        }

        if (this.config.ATIVA_CAMPO_PRIMEIRA_LOJA && !this.lojaMaisProxima) {
          invalidFields.push('Loja mais próxima');
        }
        if (this.$v.street.$invalid) {
          invalidFields.push('Rua');
        }
        if (this.$v.cep.$invalid) {
          invalidFields.push('CEP');
        }
        if (this.$v.number.$invalid) {
          invalidFields.push('Número');
        }
        if (this.$v.neighborhood.$invalid) {
          invalidFields.push('Bairro');
        }
        if (this.$v.city.$invalid) {
          invalidFields.push('Cidade');
        }
        if (this.$v.state.$invalid) {
          invalidFields.push('Estado');
        }

        if (this.$v.birth.$invalid) {
          invalidFields.push('Data de Nascimento');
        }

        if (this.$v.resposta.$invalid) {
          invalidFields.push(`${this.config.PERGUNTA_AO_CONSUMIDOR}`);
        }

        if (menorIdade) {
          return this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: 'Usuário menor de idade. Por favor acione o responsável para completar o cadastro.',
            textoBoato: 'OK',
          });
        }

        invalidFieldsString = invalidFields.join(', ');

        this.informarErrosAutenticacao('Ops...', `Campos inválidos: ${invalidFieldsString}`);
      } else {
        if (this.config.CONCORDAR_TERMOS && !this.acceptTerms)
          return this.informarErrosAutenticacao(
            'Ops...',
            `Você deve aceitar os termos que envolvem a Política de Privacidade do aplicativo.`
          );
        else if (this.config.CONCORDAR_REGULAMENTO && !this.aceitarRegulamento) {
          return this.informarErrosAutenticacao('Ops...', `Você deve aceitar o regulamento do aplicativo.`);
        } else if (this.config.VERIFICAR_CELULAR_CADASTRO) {
          if (!this.codigoAutenticacaoConfirmado) {
            const res = await this.solicitarCodigoAutenticacaoTemporariaUsuario();
            if (res) {
              this.setarTextoMetodoEnvioConfirmacaoCelular();
              this.exibirModalAutenticacaoTemporaria = true;
            }
            return;
          }
        }
        this.submitForm();
      }
    },

    submitForm() {
      this.setLoader(true);
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;

      this.consultaCpfRestrito({
        cpf: this.phone,
        campanha: this.campanha,
        wsUrl: wsUrl,
      }).then((res) => {
        if (!res || res.retorno == 'Network Error') {
          this.setLoader(false);
          return this.$swal({
            type: 'warning',
            title: 'Ops...',
            text: 'Não foi possível realizar seu cadastro. Talvez você esteja sem acesso a internet.',
          });
        }
        if (![200].includes(res.status)) {
          this.informarErrosAutenticacao('Ops...', this.config.MENSAGEM_USUARIO_RESTRITO_NA_CAMPANHA || res.msg);
        } else {
          // Cadastro por facebook
          if (this.config.LOGIN_FACEBOOK && this.facebook.token) {
            this.criarContaFacebook({
              usuario: {
                nome: this.name.toUpperCase(),
                usuario: this.phone,
                senha: this.password,
                email: this.email,
                facebook: {
                  token: this.facebook.token,
                  email: this.facebook.email,
                },
              },
              wsUrl: wsUrl,
              tipo: 'consumidor',
            }).then((response) => {
              if ([200, 201].includes(response.status)) {
                this.$emit('emmitEvent', {
                  event: 'auth',
                  data: {
                    user: this.phone,
                    password: this.password,
                    type: 'consumidor',
                    token: null,
                  },
                });
              } else {
                this.informarErrosAutenticacao('Ops...', response.msg);
              }
            });
          }
          // Cadastro normal
          else {
            this.criarConta({
              user: {
                email: this.email,
                name: this.name
                  .toUpperCase()
                  .trim()
                  .replace(/\s{2,}/g, ' '),
                phone: this.phone,
                password: this.password,
                aceites: this.aceites,
                telefoneContato: this.telefoneContato,
                indicacao: this.indicacao,
                dataNascimento: this.YYYY_MM_DD,
                telefoneContato: this.telefoneContato,
                email: this.email,
                genero: this.gender,
                endereco: {
                  endereco: this.street
                    .replace(/"/gi, "'")
                    .replace(/ü/gi, 'u')
                    .replace(/[ªº°°¹²³()\[\]{}?!=#$%¨*~^;<>|\\\/£¢¬§*°]/gi, ''),
                  numero: this.number,
                  complemento: this.complement,
                  bairro: this.neighborhood.replace(/[ªº°°¹²³]/gi, ''),
                  cidade: this.city,
                  cep: this.cep,
                  estado: this.state,
                },
                documentoVariavel: this.documentoNumero,
                documentoEstrangeiroJaCadastrado: this.documentoJaCadastrado,
                aceitaNotificacao: this.aceitaNotificacao,
                resposta: this.resposta,
                extra: this.extra,
                primeiraLoja: this.primeiraLoja,
              },
              wsUrl: wsUrl,
              type: 'consumidor',
            }).then(async (response) => {
              this.contadorCodigoAutenticacaoTemporaria++;
              if ([200, 201].includes(response.status)) {
                this.$emit('emmitEvent', {
                  event: 'usuarioCadastrado',
                });

                this.$emit('emmitEvent', {
                  event: 'gtm',
                  data: {
                    event: 'cadastroRealizado',
                    category: 'CadastroUsuario',
                    action: 'form',
                    label: 'Realizou cadastro',
                    value: this.phone,
                  },
                });
                this.$emit('emmitEvent', {
                  event: 'auth',
                  data: {
                    user: this.phone,
                    password: this.password,
                    type: 'consumidor',
                    token: null,
                  },
                });
              } else {
                const retorno = response.data.retorno ? response.data.retorno : '';
                this.informarErrosAutenticacao('Ops...', response.data.mensagem + '<br/>' + retorno);
              }
            });
          }
        }
      });
    },

    informarErrosAutenticacao(titulo, mensagem) {
      this.setLoader(false);
      this.$emit('alternar-modal', {
        event: 'informar-erros-autenticacao',
        data: {
          titulo,
          mensagem,
        },
      });
    },

    mensagemSenha() {
      if (this.$v.confirmPassword !== this.$v.password) {
        this.msgSenha = true;
      }
      console.log(this.msgSenha);
      // $v.password.minLength
    },
    verificaIdade() {
      let data = new Date();
      let nascimento = new Date(this.YYYY_MM_DD);
      let idade = data.getFullYear() - nascimento.getFullYear();
      if (
        new Date(data.getFullYear(), data.getMonth(), data.getDate()) <
        new Date(data.getFullYear(), nascimento.getMonth(), nascimento.getDate() + 1)
      )
        idade--;

      if (idade >= 18) {
        return true;
      } else {
        return false;
      }
    },
    emitirModalInformacao({ icone, titulo, mensagem, textoBotao }) {
      this.$emit('emmitInfoModal', {
        event: 'modal-informacao-generica',
        data: {
          icone,
          titulo,
          mensagem,
          textoBotao,
        },
      });
    },
    validaCidade() {
      if (this.listaCidades.length) {
        let padraoCidade = this.$v.city.$model;
        padraoCidade = padraoCidade.replace(/[àáâãä]/gi, 'a');
        padraoCidade = padraoCidade.replace(/[ç]/gi, 'c');
        padraoCidade = padraoCidade.replace(/[èéêë]/gi, 'e');
        padraoCidade = padraoCidade.replace(/[ìíîï]/gi, 'i');
        padraoCidade = padraoCidade.replace(/[òóôõö]/gi, 'o');
        padraoCidade = padraoCidade.replace(/[ùúûü]/gi, 'u');
        padraoCidade = padraoCidade.replace(/[-]/g, ' ');

        return this.listaCidades.some((e) => {
          let teste = e;

          teste = teste.replace(/[àáâãä]/gi, 'a');
          teste = teste.replace(/[ç]/gi, 'c');
          teste = teste.replace(/[èéêë]/gi, 'e');
          teste = teste.replace(/[ìíîï]/gi, 'i');
          teste = teste.replace(/[òóôõö]/gi, 'o');
          teste = teste.replace(/[ùúûü]/gi, 'u');
          teste = teste.replace(/[-]/g, ' ');

          const regexCidade = new RegExp(`${teste}`, 'i');
          return regexCidade.test(padraoCidade);
        });
      } else {
        return true;
      }
    },
    async verificarCep(val) {
      if (val.length == 9) {
        const viacep = `https://viacep.com.br/ws/${val}/json/`;
        await fetch(viacep)
          .then((response) => response.json())
          .then((data) => {
            if (!data.erro) {
              if (data.logradouro) this.street = data.logradouro;
              if (data.bairro) this.neighborhood = data.bairro;
              this.state = data.uf;
              this.city = this.preencheCidade(data.ibge) || data.localidade;
              this.buscaCidades(this.ufs.filter((e) => data.uf == e.value)[0].idIBGE);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    },
    buscaCidades: async function (idEstado) {
      this.listaCidades = [];
      const apiIBGE = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idEstado}/municipios`;
      await fetch(apiIBGE)
        .then((response) => response.json())
        .then((data) => {
          this.listaCidades = data.filter((e) => e.nome).map((e) => e.nome);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    preencheCidade: function (id) {
      const apiIBGE = `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`;
      fetch(apiIBGE)
        .then((response) => response.json())
        .then((data) => {
          return data.nome;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    async authenticate(data) {
      const loginFromPrevSession = localStorage.getItem('login');
      // caso já tenha uma sessão salva, faz a validação as credenciais para continuar
      if (loginFromPrevSession) {
        const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
        const l = JSON.parse(loginFromPrevSession);

        const casoErro = (data) => {
          let mensagem = this.config.MENSAGEM_ERRO_AUTENTICACAO_SESSAO_SALVA;
          if (!mensagem && data && data.data && data.data.mensagem) {
            mensagem = data.data.retorno;
          }

          this.signOut();

          this.$swal({
            type: 'error',
            title: 'Ops...',
            text: mensagem || 'Ocorreu um erro ao verificar suas credenciais de login. Tente novamente mais tarde.',
          });
        };

        const auth = new authentication(
          l.token,
          l.usuario,
          '',
          l.tipo,
          new this.httpClient(wsUrl, l.token),
          null,
          casoErro
        );

        this.loadAppData(loginFromPrevSession);

        const verificacao = await auth.start();
        if (verificacao.error) {
          this.signOut();

          this.config.LINK_REDIRECIONAR_DESLOGADO
            ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
            : this.$router.push({ name: 'intro' }); // força o redirecionamento para a tela inicial

          return null;
        } else {
          return this.loadAppData(loginFromPrevSession);
        }
      }

      const response = await this.signIn({
        user: data.user,
        password: data.password,
        type: data.type,
        token: data.token || null,
        tokenFacebook: data.tokenFacebook || null,
        isProd: this.isProd,
      });
      if ([200, 201].includes(response.status)) {
        // Load application data (user)
        const login = localStorage.getItem('login');

        if (login) {
          this.logouAgora = true;

          if (this.integracaoGTM) {
            this.emitirEventoGTM({
              event: 'loginRealizado',
              category: 'LoginUsuario',
              action: 'form',
              label: 'Realizou login',
              value: data.user,
            });
          }

          this.loadAppData(login, response.loginAnimation);
        } else {
          this.signOut();

          this.config.LINK_REDIRECIONAR_DESLOGADO
            ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
            : this.$swal({
                type: 'error',
                title: 'Ops...',
                text: 'Ocorreu um erro durante sua tentativa de login! Tente novamente mais tarde.',
              });

          // força o redirecionamento para a tela inicial
          this.$router.push({ name: 'intro' });
        }
      } else {
        this.signOut();

        const mensagem = response.msg.match(/Login inativo/)
          ? this.config.MENSAGEM_LOGIN_INATIVO || response.msg
          : response.msg;

        this.config.LINK_REDIRECIONAR_DESLOGADO
          ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
          : this.$swal({
              type: 'warning',
              title: 'Ops...',
              text: mensagem,
            });

        // força o redirecionamento para a tela inicial
        this.$router.push({ name: 'intro' });
      }
    },
    async loadAppData(login, loginAnimation) {
      // Default fallback action
      const defaultFallback = (msg) =>
        this.$swal({
          type: 'info',
          title: 'Ops...',
          text: msg,
          onClose: () => location.reload(),
        });
      // Adicionar campo aceitaNotificacao caso conta criar agora
      if (this.lgpdNotificacoes) {
        await this.alterarNotificacao({
          data: {
            telefone: JSON.parse(login).usuario,
            aceitaNotificacao: true,
          },
        }).then((res) => {
          if (![200, 201].includes(res.status)) {
            console.log(res.data.retorno);
            this.$swal({
              type: 'error',
              title: 'Ops...',
              html: res.msg + '<br/>' + res.data.retorno,
            });
          }
        });
        this.salvarNotificacoes({ notificacoes: false });
      }
      // Loads user profile
      // console.log(JSON.parse(login));
      const profileResponse = await this.loadProfile({
        login: JSON.parse(login),
      });

      if (this.primeiroAcessoDados && this.emailLogin && this.profile) {
        const email = this.emailLogin;
        if (email) {
          const respostaPerfil = await this.atualizarEmail({
            profile: { ...this.profile, email: email },
          });
          if ([200, 201].includes(respostaPerfil.status)) {
            console.log('Atualizado com sucesso');
            await this.limparPrimeiroAcesso();
          } else {
            console.log('Erro ao atualizar', respostaPerfil);
          }
        }
      }

      // Enviar device ID
      if (this.logouAgora && this.aceitaNotificacao && window.qrsorteios.deviceId && !this.profile.cadastroInicial) {
        const ferramentasNotificacao = this.config.FERRAMENTA_ENVIO_NOTIFICACOES.split(',')
          .map((e) => e.trim())
          .filter((e) => e);

        if (ferramentasNotificacao.includes('sns') && typeof cordova !== 'undefined') {
          this.enviarDeviceID({
            data: {
              id: window.qrsorteios.deviceId,
              descricao: window.qrsorteios.device,
              usuario: this.profile.telefone,
              servicoNotificador: 'sns',
              tipoDispositivo: cordova.platformId,
            },
          }).then((res) => {
            if (![200, 201].includes(res.status)) {
              this.emitirModalInformacao({
                icone: 'fas fa-exclamation-circle',
                titulo: 'Ops...',
                mensagem: res.msg,
                textoBoato: 'OK',
              });
            }
          });
        }

        if (ferramentasNotificacao.includes('onesignal') && typeof cordova === 'undefined') {
          this.enviarDeviceID({
            data: {
              id: window.qrsorteios.deviceId,
              descricao: window.qrsorteios.device,
              usuario: this.profile.telefone,
              servicoNotificador: 'onesignal',
              tipoDispositivo: 'pwa',
            },
          }).then((res) => {
            if (![200, 201].includes(res.status)) {
              this.emitirModalInformacao({
                icone: 'fas fa-exclamation-circle',
                titulo: 'Ops...',
                mensagem: res.msg,
                textoBoato: 'OK',
              });
            }
          });
        }
      }

      if ([200].includes(profileResponse.status)) {
        if (campaign.getCampaign()) {
          // Campaign and current date
          const currentCampaign = campaign.getCampaign();
          const currentDate = Data.dataHoraAtual();

          if (
            currentDate >= `${currentCampaign.dataInicial} 00:00:00` &&
            currentCampaign.ativa &&
            currentDate <= `${currentCampaign.dataFinal} 23:59:00`
          ) {
            this.setCampaign({ campaign: currentCampaign });
            this.loadUserData(loginAnimation);

            // Recarregando campanha
            const campaignResponse = await this.loadCampaign({ currentDate });
            if (![200].includes(campaignResponse.status)) {
              localStorage.removeItem('login');
              campaign.removeCampaign();
              return defaultFallback(campaignResponse.msg);
            }
          } else if (
            // Veridicando se alguma campanha foi encerranda e ainda está ativa, salvando no localstorage
            currentDate >= `${currentCampaign.dataInicial} 00:00:00` &&
            currentCampaign.ativa &&
            currentDate > `${currentCampaign.dataFinal} 23:59:00`
          ) {
            this.setClosedCampaign({ closedCampaign: currentCampaign });
            // loadUserData aqui

            // Recarregando campanha
            const campaignResponse = await this.loadCampaign({ currentDate });
            if (![200].includes(campaignResponse.status)) {
              localStorage.removeItem('login');
              campaign.removeCampaign();
              // Removendo campanhas inativas do local storage, caso exista
              if (localStorage.getItem('ClosedCampaign')) {
                localStorage.removeItem('ClosedCampaign');
              }
              //return defaultFallback(campaignResponse.msg)
            }
            this.loadUserData(loginAnimation);
          } else {
            localStorage.removeItem('login');
            if (localStorage.getItem('ClosedCampaign')) {
              localStorage.removeItem('ClosedCampaign');
            }
          }
        } else {
          const currentDate = Data.dataHoraAtual();
          const campaignResponse = await this.loadCampaign({ currentDate });
          if (![200].includes(campaignResponse.status)) {
            localStorage.removeItem('login');
            campaign.removeCampaign();
            return defaultFallback(campaignResponse.msg);
          } else {
            // Caso a campanha esteja encerrada, ela também será salva no local storage, desede que esteja ativa (campanha.store)
            this.loadUserData(loginAnimation);
          }
        }
        // Errors fallback
        if (![200].includes(profileResponse.status)) {
          localStorage.removeItem('login');
          campaign.removeCampaign();
          return defaultFallback('Não foi possível carregar seu perfil!');
        }

        this.setIntegracaoComper({
          campaign: this.currentCampaign,
          profile: this.profile,
        });

        this.obterConsumidorCupom({
          telefone: this.profile.telefone,
        });
      }
    },
    verificaNumeroTelefoneContato() {
      if (this.telefoneContatoJaUtilizado && this.config.VERIFICAR_CELULAR_CADASTRO) {
        this.verificadorTelefoneContatoValido = false;
      } else if (this.config.VERIFICAR_CELULAR_CADASTRO) {
        this.verificadorTelefoneContatoValido = this.verificaNumeroDeCelularValido(this.telefoneContato);
      } else {
        this.verificadorTelefoneContatoValido = false;
      }
    },

    verificaNumeroDeCelularValido(numeroCelular) {
      const regexValidador = /^[1-9]{2}[9][6789]\d{7}$/;
      try {
        if (!numeroCelular) return false;

        return regexValidador.test(numeroCelular);
      } catch (err) {
        return false;
      }
    },

    formataTelefone(tel) {
      if (!tel) return;

      tel = tel.replace(/\D/g, '');
      tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
      tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
      return tel;
    },

    async solicitarCodigoAutenticacaoTemporariaUsuario() {
      if (this.tempoInicial > 0 && this.tempoInicial < 30) {
        this.exibirModalAutenticacaoTemporaria = true;
        return;
      }

      this.limparIntervaloTempo();
      this.tempoInicial = 30;
      this.contadorReenviarConfirmacaoCelular();

      this.enviandoRequisicaoConfirmarCelular = true;
      const resposta = await this.solicitarCodigoAutenticacaoTemporaria({
        usuario: this.telefoneContato,
        isProd: this.isProd,
      });
      this.enviandoRequisicaoConfirmarCelular = false;

      const { status, msg } = resposta;

      if (status === 200) {
        return true;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem:
          msg ||
          'Erro ao solicitar confirmação de número de celular, tente novamente ou verifique sua conexão com a rede!',
        textoBoato: 'OK',
      });
    },

    async verificaCodigoAutenticacaoTemporariaUsuario() {
      const resposta = await this.verificaCodigoAutenticacaoTemporaria({
        codigo: this.codigoAutenticacaoTemporaria,
        usuario: this.telefoneContato,
        isProd: this.isProd,
      });

      const { status, retorno, msg } = resposta;

      if (status == 200 && retorno.codigoValido) {
        this.submitForm();
        return;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem:
          msg == 'Erro ao verificar o código de autenticação temporária: Código de autenticação inválido/expirado.'
            ? 'Tente novamente, pois o código de verificação está inválido/expirado.'
            : msg,
        textoBoato: 'OK',
      });
      this.contadorCodigoAutenticacaoTemporaria += 1;
    },

    setarTextoMetodoEnvioConfirmacaoCelular() {
      if (this.config.METODO_ENVIO_CONFIRMAR_CELULAR === 'sms') {
        this.textoExibicaoModalConfirmarCelular = 'SMS';
      }

      if (this.config.METODO_ENVIO_CONFIRMAR_CELULAR === 'whatsapp') {
        this.textoExibicaoModalConfirmarCelular = 'WhatsApp';
      }
    },

    reenviarRequisicaoConfirmarCelular() {
      this.solicitarCodigoAutenticacaoTemporariaUsuario();
    },

    contadorReenviarConfirmacaoCelular() {
      if (this.tempoInicial > 0 && this.tempoInicial <= 30) {
        this.intervaloReenviarConfirmacaoCelular = setInterval(() => {
          this.tempoInicial--;

          if (this.tempoInicial <= 0) {
            clearInterval(this.intervaloReenviarConfirmacaoCelular);
            return;
          }
        }, 1000);
      }
    },

    limparIntervaloTempo() {
      clearInterval(this.intervaloReenviarConfirmacaoCelular);
    },

    async verificaTelefoneContatoAntesCadastrar() {
      const resultado = await this.verificaTelefoneContato({
        telefoneContato: this.telefoneContato,
        isProd: this.isProd,
      });

      if (resultado.status === 400) {
        this.telefoneContatoJaUtilizado = true;

        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Já existe outra conta cadastrada com este mesmo número de celular!',
          textoBoato: 'OK',
        });
        return;
      }

      this.telefoneContatoJaUtilizado = false;
    },
    async obterLojasMaisProximasUsuario(latitude, longitude) {
      const resposta = await this.obterLojasMaisProximas({
        latitude,
        longitude,
        isProd: this.isProd,
      });

      const { status, retorno, msg } = resposta;

      if (status == 200) {
        this.lojasMaisProximas = retorno;
        this.lojasMaisProximasOptions = [
          this.lojasMaisProximasDefault,
          ...retorno.map((associado) => ({
            desc: `${associado.nome} (${associado.distancia.toFixed(1)} Km)`,
            value: associado.cnpjCpf,
          })),
        ];
        return;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem: msg,
        textoBoato: 'OK',
      });
    },

    async obterCoordenadas() {
      const address = `${this.street}, ${this.neighborhood}, ${this.city}, ${this.state}, ${this.cep}`;
      if(address == this.address){
        console.log('não necessário obter coords')
      }
      else{
        console.log('obtendo coords')
        this.address = address;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const { lat, lng } = results[0].geometry.location;
            this.latitude = lat();
            this.longitude = lng();
            // ...
          } else {
            console.log('Erro ao geocodificar o endereço:', status);
          }
        });
      }
    },
  },

  watch: {
    cep(x) {
      this.verificarCep(x);
    },

    codigoAutenticacaoTemporaria: async function (codigo) {
      if (codigo && codigo.length === 6) {
        await this.verificaCodigoAutenticacaoTemporariaUsuario();
      }
    },
    async telefoneContato() {
      if (this.verificadorTelefoneContatoValido || this.telefoneContato.length >= 11) {
        await this.verificaTelefoneContatoAntesCadastrar();
      }
    },
    jaECliente(){
      if(this.jaECliente === 'false' && this.config.CADASTRO_LOJAMAISPROXIMA && this.street != '' && this.city != '' && this.state != '') {
        this.obterCoordenadas();
      }
    },
    street(){
      if(this.jaECliente === 'false' && this.config.CADASTRO_LOJAMAISPROXIMA && this.street != '' && this.city != '' && this.state != '') {
        this.obterCoordenadas();
      }
    },
    latitude(x) {
      if (this.config.CADASTRO_LOJAMAISPROXIMA) {
        this.obterLojasMaisProximasUsuario(this.latitude, this.longitude);
      }
    },
  },

  props: {
    telaPreCadastro: {
      type: String,
      required: true,
    },
    nomeUsuario: {
      type: String,
      required: false,
    },
    emailUsuario: {
      type: String,
      required: false,
    },
    phoneUsuario: {
      type: String,
      required: false,
    },
    autocompletarCadastro: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  validations() {
    return {
      email: {
        required,
        isEmail: Validation.email,
      },
      name: {
        required,
        minLength: minLength(3),
        isName: this.config.LOGIN_CPF
          ? this.config.TIPO_USUARIO === 'cpfCnpj'
            ? this.phone.length === 11
              ? Validation.personName
              : Validation.abbreviatedName
            : this.config.TIPO_USUARIO === 'cnpj'
            ? Validation.abbreviatedName
            : Validation.personName
          : true,
      },
      phone: {
        required,
        minLength: minLength(10),
        isValid: this.config.LOGIN_CPF
          ? this.config.TIPO_USUARIO == 'cpfCnpj'
            ? Validation.cpfCnpj
            : this.config.TIPO_USUARIO == 'cnpj'
            ? Validation.cnpj
            : Validation.cpf
          : true,
      },
      password: {
        required,

        containsNumbers: (value) => /\d/.test(value),
        containsUpperAndLower: (value) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value),
        minLength: (value) => /^.{8,}$/.test(value),
      },
      confirmPassword: {
        required,

        sameAs: sameAs('password'),
        containsNumbers: (value) => /\d/.test(value),
        containsUpperAndLower: (value) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value),
        minLength: (value) => /^.{8,}$/.test(value),
      },
      telefoneContato: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('telefoneContato') ? required : false,
        minLength: minLength(10),
      },
      birth: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') ? required : false,
        minLength: minLength(10),
        isMaiorDeIdade: this.config.VERIFICAR_IDADE_USUARIO ? this.verificaIdade : '',
      },
      gender: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero') ? required : false,
      },
      street: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
        minLength: minLength(5),
      },

      cep: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
        isCep: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? Validation.cep : '',
        minLength: minLength(8),
      },

      number: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
      },

      neighborhood: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
        minLength: minLength(1),
      },

      city: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
        minLength: minLength(3),
        cidadeValida: this.validaCidade,
      },

      state: {
        required: this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') ? required : false,
        minLength: minLength(2),
      },
      resposta: {
        required: this.config.MODULO_PERGUNTA_E_RESPOSTA ? required : false,
      },
    };
  },
};
</script>

<style>
.destacar-placeholder::placeholder {
  font-weight: 800;
}

.destacar {
  color: #8c8c8c !important;
  font-weight: 800;
}
</style>
<style scoped lang="scss">
.container-cadastro {
  max-height: 80vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to bottom, var(--color-primary), var(--color-secondary));
    border-radius: 20px;
  }
}

.aviso-senha {
  font-size: 15px;
  font-weight: 600;
  color: #fc3434;
}
.validacaoErro {
  visibility: hidden;
  max-height: 0;
}
.validacaoGrupo:focus-within .validacaoErro {
  visibility: visible;
  max-height: none;
}

.aviso-senha i,
.aviso-senha span,
.iconConfimacaoSenha {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aviso-senha-bg {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 8px;
}

@media screen and (max-width: 490px) {
  .aviso-senha {
    font-size: 11px;
  }

  .aviso-senha-bg {
    padding: 6px;
  }
}
</style>
